import React from "react";
import "../../css/common/common-button.css";

type ButtonProps = {
    text: string;
    onClick?: () => void; // делаем onClick опциональным
};

const CommonButton: React.FC<ButtonProps> = ({ text, onClick }) => {
    const handleClick = () => {
        if (onClick) {
            onClick(); // вызываем onClick, если он определен
        }
    };

    return (
        <div className="button" onClick={handleClick}>
            {text}
        </div>
    );
}

export default CommonButton;
