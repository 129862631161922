import React, { useState, useEffect, useRef } from "react";
import "../../css/common/common-filter-container.css";

interface CommonFilterContainerProps {
    categories: string[];
    setActiveCategory: (category: string | null) => void;
    handleCategorySelection: (category: string) => void;
    initialCategory?: string | null; // Проп для передачи активной категории
}

const Filters: React.FC<CommonFilterContainerProps> = ({
                                                           categories,
                                                           setActiveCategory,
                                                           handleCategorySelection,
                                                           initialCategory,
                                                       }) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    useEffect(() => {
        if (initialCategory && categories.includes(initialCategory)) {
            const initialIndex = categories.indexOf(initialCategory);
            setActiveIndex(initialIndex);
        } else {
            setActiveIndex(null); // Если нет начальной категории, не выбираем ничего
        }
    }, [initialCategory, categories]);

    const handleCategoryClick = (index: number) => {
        setActiveIndex(index);
        setActiveCategory(categories[index]);
        handleCategorySelection(categories[index]);
    };

    return (
        <div className="library_filter_container">
            {categories.map((category, index) => (
                <p
                    key={index}
                    className={`library_filter_button ${
                        activeIndex === index ? "active" : ""
                    }`}
                    onClick={() => handleCategoryClick(index)}
                >
                    {category}
                </p>
            ))}
        </div>
    );
};

export default Filters;
