import React, { useState } from "react";
import "../../css/common/common-search-panel.css";
import CommonSearchInput from "./CommonSearchInput";
// @ts-ignore
import searchIcon from "../../img/ic_refrash.svg"; // Замените имя файла, если используете другой значок для поиска
// @ts-ignore
import filter from "../../img/filter_button.svg";
import CommonButton from "./CommonButton";
// @ts-ignore
import ic_filter from "../../img/ic_filter.png"
interface LibrarySearchPanelProps {
    placeholder: string;
    onSearch: (text: string) => Promise<void>;
    showSearch: boolean;
    onReload: () => Promise<void>;
    showFilter?: boolean;
    onFilter?: (id: number) => Promise<void>;
    filterCategories?: string[]; // Изменено на string[] для совместимости
}

const CommonSearchPanel: React.FC<LibrarySearchPanelProps> = ({
                                                                  placeholder,
                                                                  onSearch,
                                                                  showSearch,
                                                                  onReload,
                                                                  showFilter,
                                                                  onFilter,
                                                                  filterCategories
                                                              }) => {
    const [searchText, setSearchText] = useState<string>("");
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false); // Состояние для открытия фильтра

    const handleSubmit = async (text: string) => {
        await onSearch(text);
    };

    const handleSearch = async () => {
        await onSearch(searchText);
    };

    const toggleFilter = () => {
        setIsFilterOpen(!isFilterOpen); // Инвертируем состояние открытия фильтра
    };

    const handleFilter = async (id: number) => {
        if (onFilter) {
            await onFilter(id);
            toggleFilter(); // После фильтрации закрываем фильтр
        }
    };

    return (
        <>
            <div className="library_search_panel">
                <div className="search_panel">
                    <CommonSearchInput
                        placeholder={placeholder}
                        onSubmit={handleSubmit}
                        value={searchText} // Привязываем значение поля ввода к состоянию
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)} // Обновляем состояние при изменении поля ввода
                    />
                </div>
                {showSearch && (
                    <CommonButton text="Найти" onClick={handleSearch}/>
                )}
                {showFilter && onFilter && (
                    <div className="filter-button" onClick={toggleFilter}>
                        <img src={ic_filter} width={20}/>
                        <span className="filter-text">Фильтр</span>

                    </div>
                )}
            </div>
            {isFilterOpen && (
                <div className="filter-panel">
                    {filterCategories?.map((category, index) => (
                        <button
                            key={index}
                            onClick={() => handleFilter(index)} // Передаем индекс категории в onFilter
                            className="filter-category"
                        >
                            {category}
                        </button>
                    ))}
                </div>
            )}
        </>
    );
}

export default CommonSearchPanel;
