import { InfoEditor } from '../../interface/infoEditor';
import { HotelInfo, TeamMember } from '../../interface/hotelInfo';
import {start} from "node:repl";
import {Simulate} from "react-dom/test-utils";
import config from "../../config";
import {RoleAccess, User} from "../../interface/personInfo";
import {UserInfo} from "node:os";

class PersonInfoEditor implements InfoEditor {
    private userInfo: User[];
    private filterChoices: string[] = [];

    constructor() {
        this.userInfo = [new class implements User {
            name: string= '1';
            phone: string= '1';
            salary: number=1
            bonus: number=1
            password: string | null= '';
            rating: number=1
            comment: string= '1';
            emotions: number=1
            hotel_id: number=1
            role_id: number=1
            work_list: any;
            image_id: number=1
            date_reg: number=1
            days_of_work: number=1
            id: number=1
            role_name: string= '1';
            role_index: number=1;
            role_access: RoleAccess[]= [];
            hotel_name: string= '1';
            hotel_address: string= '1';
            hotel_coordinates: string= '1';
            hotel_phone: string= '1';
            hotel_owner_name: string= '1';
            hotel_owner_phone: string = '1';

        }];
    }

    async getAllInfo(start: number, end: number, observer: (info: any) => void): Promise<void> {
        try {
            const url = config.apiBaseUrl + `/api/persons?start=${start}&end=${end}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const information = await response.json();
            observer(information); // This was using this.userInfo, changed to information
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }
//вот тут список всех отелей
    getFilterInfo(start: number, end: number, id: number, observer: (info: any) => void): void {
        // const teamMembers = this.userInfo.flatMap(hotel => hotel.team.filter(member => member.hotel_id === id)).slice(start, end);
        // observer(teamMembers);
    }

    async getSearchInfo(name: string, observer: (info: any) => void): Promise<void> {
        try {
            const url = config.apiBaseUrl + "/api/persons/search?name="  + name;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const information = await response.json();
            observer(information);
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }
//вот тут тоже изменить
    getContextInfo(): Array<Map<string, any>> {
        return this.userInfo.map(user => {
            const map = new Map<string, any>();
            for (const key in user) {
                if (user.hasOwnProperty(key)) {
                    map.set(key, user[key as keyof User]);
                }
            }
            return map;
        });
    }

    getFilterChoices(): string[] {
        return this.filterChoices;
    }

    getEditForm(): string {
        return "/forms/edit-hotel"
    }

    async deleteVar(id: number, observer: (info: any) => void): Promise<void> {
        try {
            const url = config.apiBaseUrl + `/api/persons/${id}/profile`;
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'

            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            observer(await response.json());
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    initChoices(): void {

    }

    setChoices(choices: string[]): void {
        this.filterChoices = choices;
    }

    getAddForm(): string {
        return "/forms/create-person"

    }

    getTranslationsInfo(): { [key: string]: string } {
        // Пример реализации метода
        const translations: { [key: string]: string } = {
            role: "Роль",
            hotel_name: "Имя отеля",
            phone: "Телефон",
            price: "Зарплата",
            bonus: "Бонус",
            rating: "Рейтинг",
            comment: "Комментарий",

        };
        return translations;
    }
}

export default PersonInfoEditor;
