import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '../css/common/common-app.css';

import Person_cabinet_main from "./library/LibraryMain";
import RegistrationFormLogin from "./registration_form/RegistrationFormLogin";
import RegistrationFormPassword from "./registration_form/RegistrationFormPassword";
import RegistrationFormReset from "./registration_form/RegistrationFormReset";
import LibraryMain from "./library/LibraryMain";
import ActivityInfo from "./library/ActivityInfo";
import Service_main from "./service/service_menu/ServiceMain";
import ServiceMain from "./service/service_menu/ServiceMain";
import ServiceElement from "./service/service_element/ServiceElement";
import FormItemCreate from "./forms/FormItemCreate";
import FormItemEdit from "./forms/FormItemEdit";

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    {/*<Route path="/person_cabinet" element={<PersonCabinetMain />} />*/}
                    <Route path="/" element={<RegistrationFormLogin />} />
                    <Route path="/password" element={<RegistrationFormPassword />} />
                    <Route path="/reset" element={<RegistrationFormReset />} />
                    <Route path="/library" element={<LibraryMain />} />
                    {/*<Route path="/notifications" element={<NotifcationMain />} />*/}
                    <Route path="/activity" element={<ActivityInfo />} />
                    <Route path="/services" element={<ServiceMain />} />
                    <Route path="/service" element={<ServiceElement />} />

                    {/*<Route path="/forms/create-hotel" element={<FormHotelCreate />} />*/}
                    {/*<Route path="/forms/edit-hotel" element={<FormHotelEdit />} />*/}
                    <Route path="/forms/create-activity" element={<FormItemCreate />} />
                    <Route path="/forms/edit-activity" element={<FormItemEdit />} />
                    {/*<Route path="/forms/create-person" element={<FormPersonCreate />} />*/}

                </Routes>
            </div>
        </Router>
    );
}



export default App;
