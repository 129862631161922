// Компонент Dropdown
import React, { useEffect } from 'react';

interface DropdownProps {
    label: string;
    name: string;
    options: string[];
    onChange: (name: string, value: string) => void;
    value?: string; // необязательный параметр для текущего значения
}

const Dropdown: React.FC<DropdownProps> = ({ label, name, options, onChange, value }) => {
    // Определяем значение по умолчанию как первый элемент из options, если value не задано
    const defaultValue = options.length > 0 ? options[0] : '';

    // Устанавливаем начальное значение при первом рендере, если value не задано
    useEffect(() => {
        if (!value && options.length > 0) {
            onChange(name, defaultValue);
        }
    }, [value, options, name, onChange, defaultValue]);

    // Выбираем значение для select
    const selectedValue = value || defaultValue;

    return (
        <div className="primitive-container">
            <label>{label}</label>
            <select
                name={name}
                value={selectedValue}
                onChange={(e) => {
                    onChange(name, e.target.value);
                }}
            >
                {options.map((option, index) => (
                    <option className="option-change" key={index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Dropdown;
