import React, { useState } from 'react';
import '../../../css/services/service_list_item.css'; // Import styles
// @ts-ignore
import deleteImage from "../../../img/ic_delete.svg"; // Import images
// @ts-ignore
import more_button from "../../../img/ic_more_button.svg";
// @ts-ignore
import editButton from "../../../img/ic_edit_button.svg";

interface ListItemProps<T> {
    item: T;
    onDelete: () => void;
    onEdit: () => void;
    translations: { [key: string]: string }; // Translation object
}

const ListItem = <T extends { name: string; text: string }>({ item, onDelete, onEdit, translations }: ListItemProps<T>) => {
    const [showInformation, setShowInformation] = useState<boolean>(false);
    const [isRemoving, setIsRemoving] = useState<boolean>(false);

    const switchMode = () => {
        setShowInformation(!showInformation);
    };

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            setIsRemoving(true);
            // Устанавливаем таймер на удаление элемента после завершения анимации (например, 300ms)
            setTimeout(() => {
                onDelete();
            }, 300); // Duration matches the CSS animation duration
        }
    };

    return (
        <div className={`service-list-item ${isRemoving ? 'removing' : ''}`}>
            <div className="service-list-row">
                <img
                    src={more_button}
                    alt="more_button"
                    className={`more-button ${showInformation ? 'rotated' : ''}`}
                    onClick={switchMode}
                />
                <div className="service-list-item-content">
                    <p>{item.name}</p>
                </div>
                <div className="option-button">
                    <img src={editButton} alt="edit_button" className="edit-button" onClick={onEdit} />
                    <img src={deleteImage} alt="delete_image" className="delete-button" onClick={handleDelete} />
                </div>
            </div>
            {showInformation && (
                <div className="item-details">
                    {Object.keys(translations).map((key) => (
                        item[key as keyof T] !== undefined && (
                            <div key={key}>
                                <strong>{translations[key]}: </strong>
                                {item[key as keyof T]?.toString()}
                            </div>
                        )
                    ))}
                </div>
            )}
        </div>
    );
};

export default ListItem;
