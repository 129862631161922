import React from 'react';

interface TextAreaProps {
    label: string;
    placeholder: string;
    name: string;
    onChange: (name: string, value: string) => void;
    value?: string; // необязательный параметр
}

const TextArea: React.FC<TextAreaProps> = ({ label, placeholder, name, onChange, value }) => (
    <div className="primitive-container">
        <label>{label}</label>
        <textarea
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(name, e.target.value)}
        />
    </div>
);

export default TextArea;
