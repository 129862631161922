import { Publisher } from "../interface/publisher";
import { AccountInfo } from "../interface/accountInfo";
import { HotelInfo } from "../interface/hotelInfo";
import {Item} from "../interface/library";
import config from "../config";
class ModelLibrary implements Publisher {
    private static instance: ModelLibrary;
    private info: Item[] | null = null;
     private baseUrl: string = config.apiBaseUrl + '/api/library/activities';
    private observers: ((info: Item | null) => void)[] = [];

    private constructor() {}

    public static getInstance(): ModelLibrary {
        if (!ModelLibrary.instance) {
            ModelLibrary.instance = new ModelLibrary();
        }
        return ModelLibrary.instance;
    }

    public getInfo(): Item[] | null {
        return this.info;
    }

    // Method to fetch account info using token
    public async fetchLibraryInfo(): Promise<void> {
        try {
            // Выполняем первый запрос
            const response1 = await fetch(this.baseUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            });
            if (!response1.ok) {
                throw new Error('Network response was not ok');
            }
            this.info = await response1.json();

            this.notify();
        } catch (error) {
            console.error('Fetch account with token error:', error);
        }
    }

    // Publisher methods
    // @ts-ignore
    public subscribe(observer: OmitThisParameter<(info: (AccountInfo | null)) => void>): void {
        // @ts-ignore
        this.observers.push(observer);
    }
    public async fetchByID(id: number): Promise<Item | null> {
        try {
            const response = await fetch(`${config.apiBaseUrl}/api/library/activities/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const item = await response.json();
            return item;
        } catch (error) {
            console.error('Error fetching item by ID:', error);
            return null;
        }
    }
    // @ts-ignore
    public unsubscribe(observer: (info: Item | null) => void): void {
        this.observers = this.observers.filter(subscriber => subscriber !== observer);
    }

    public notify(): void {
        for (const observer of this.observers) {
            if (this.info) {
                // @ts-ignore
                observer(this.info);
            } else {
                observer(null);
            }
        }
    }
}

export default ModelLibrary;
