import React from "react";
import "../../css/common/common-header.css";
// @ts-ignore
import logo_high_qu from "../../img/logo_high_qu.jpg"
function CommonHeader(props: { name: string }) {
    return (
        <div className="header">
            <p className="header-text">{props.name}</p>
            <img src={logo_high_qu} alt="logo" className="logo"/>
        </div>
    );
}

export default CommonHeader;
