import React, { useEffect, useState } from "react";
import "../../css/library/library-main.css";
import CommonNavigation from "../common/CommonNavigation";
import LazyList from "../common/LazyList";
import LibraryViewModel from "../../viewmodel/LibraryViewModel";
import CommonHeader from "../common/CommonHeader";
import CommonSearchPanel from "../common/CommonSearchPanel";
import Filters from "../common/CommonFilterContainer";
import ListItem from "./ListItem";
import { useNavigate, useLocation } from "react-router-dom";
import { Item } from "../../interface/library";

function LibraryMain() {
    const [activeCategory, setActiveCategory] = useState<string | null>(null);
    const [searchText, setSearchText] = useState<string>("");
    const [libraryInfo, setLibraryInfo] = useState<Item[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadingText, setLoadingText] = useState<string>("Запрос к серверу");
    const viewModel = LibraryViewModel.getInstance();
    const navigate = useNavigate();
    const location = useLocation();

    const categories: string[] = ["Конкурсы", "Сценарии", "Инструкции", "Флешмобы"];

    // Анимация точек
    useEffect(() => {
        let dotIndex = 0;
        const dots = ['.', '..', '...'];
        const interval = setInterval(() => {
            setLoadingText(`Запрос к серверу${dots[dotIndex]}`);
            dotIndex = (dotIndex + 1) % dots.length;
        }, 500);

        return () => clearInterval(interval);
    }, []);

    // Проверка авторизации при загрузке компонента
    useEffect(() => {
        if (!viewModel.handleCheckAuth()) {
            navigate(`/`);
        }
    }, [navigate]);

    // Извлечение параметров из query при загрузке компонента
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const queryCategory = queryParams.get("type");
        const querySearch = queryParams.get("search");

        if (querySearch) {
            setSearchText(querySearch);
            handleSubmit(querySearch);
        } else if (queryCategory && categories.includes(queryCategory)) {
            setActiveCategory(queryCategory);
            getByType(queryCategory);
        } else {
            setActiveCategory(null); // Устанавливаем активную категорию в null
            viewModel.fetchLibraryInfo().then(() => setLibraryInfo(viewModel.getInfo()));
        }
        setIsLoading(false); // Останавливаем загрузку
    }, [location.search]);

    // Обновление библиотечных данных при изменении категории
    useEffect(() => {
        if (activeCategory) {
            // @ts-ignore
            async function fetchData() {
                setIsLoading(true);
                getByType(activeCategory);
                setIsLoading(false);
            }
            fetchData();
        } else {
            // Fetch data when no category is selected
        }

        const handleViewModelUpdate = () => {
            const info = viewModel.getInfo();
            setLibraryInfo(info);
        };

        viewModel.subscribe(handleViewModelUpdate);

        return () => {
            viewModel.unsubscribe(handleViewModelUpdate);
        };
    }, [activeCategory]);

    const categoryAdapter = (category: string) => {
        switch (category) {
            case "Конкурсы":
                return "Конкурс";
            case "Сценарии":
                return "Сценарий";
            case "Инструкции":
                return "Инструкция";
            case "Флешмобы":
                return "Флешмоб";
            default:
                return "Конкурс";
        }
    };

    const getByType = (category: string|null) => {
        if(category){
        const info = viewModel.getByType(categoryAdapter(category));
        setLibraryInfo(info ?? null);
            }
    };

    const handleSubmit = async (text: string) => {
        setIsLoading(true);
        await viewModel.searchLibraryInfo(text);
        setIsLoading(false);
    };

    const handleRefresh = async () => {
        setIsLoading(true);
        await viewModel.fetchLibraryInfo();
        setSearchText("");
        setIsLoading(false);
    };

    // Обработчик выбора категории с обновлением URL
    const handleCategorySelection = (category: string | null) => {
        if (category == null) {
            return;
        }
        if (category !== activeCategory) {
            setActiveCategory(category);
            const queryParams = new URLSearchParams(location.search);
            queryParams.set("type", category);
            queryParams.delete("search");
            navigate(`?${queryParams.toString()}`);
        }
    };

    // Обработчик поиска с обновлением URL
    const handleSearch = async (searchQuery: string) => {
        setSearchText(searchQuery);
        setActiveCategory(null)
        const queryParams = new URLSearchParams(location.search);
        queryParams.set("search", searchQuery);
        queryParams.delete("type");
        navigate(`?${queryParams.toString()}`);
        await handleSubmit(searchQuery);
    };

    return (
        <>
            <CommonNavigation />
            <div className="library">
                <div className="library_container">
                    <CommonHeader name="Библиотека" />
                    <Filters
                        categories={categories}
                        setActiveCategory={handleCategorySelection}
                        handleCategorySelection={handleCategorySelection}
                        initialCategory={activeCategory || undefined} // Передаем активную категорию, или ничего
                    />
                    <div className="library_container_content">
                        <CommonSearchPanel
                            onReload={handleRefresh}
                            onSearch={handleSearch}
                            placeholder={"Поиск активностей.."}
                            showSearch={true}
                        />
                        {isLoading ? (
                            <p className="loading-message">{loadingText}</p>
                        ) : (
                            <LazyList items={libraryInfo} ListItemComponent={ListItem} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default LibraryMain;
