import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../css/library/library-activity-info.css';
import LibraryViewModel from '../../viewmodel/LibraryViewModel';
// @ts-ignore
import back_image from "../../img/ic_back_arrow_vector.svg";
import CommonButton from "../common/CommonButton";
import config from "../../config";
import CommonHeader from "../common/CommonHeader";
import CommonNavigation from "../common/CommonNavigation";

const ActivityInfo: React.FC = () => {
    const [mediaSrc, setMediaSrc] = useState<string>('https://via.placeholder.com/600x400/cccccc/cccccc');  // Заглушка через URL
    const [isVideo, setIsVideo] = useState<boolean>(false);
    const [info, setInfo] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);  // Состояние для отслеживания загрузки
    const [loadingText, setLoadingText] = useState('Загрузка данных');
    const location = useLocation();
    const navigate = useNavigate();
    const viewModel = LibraryViewModel.getInstance();
    const dots = ['.', '..', '...'];

    // Анимация точек "..." в загрузочном сообщении
    useEffect(() => {
        let dotIndex = 0;
        const interval = setInterval(() => {
            setLoadingText(`Загрузка данных${dots[dotIndex]}`);
            dotIndex = (dotIndex + 1) % dots.length; // Перебираем точки циклично
        }, 500); // Интервал в 500 миллисекунд

        return () => clearInterval(interval); // Очистка таймера при размонтировании компонента
    }, []);

    const getQueryParam = (param: string): string | null => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get(param);
    };

    const idString = getQueryParam('id');
    const id = idString ? parseInt(idString, 10) : null;

    useEffect(() => {
        const fetchInfo = async () => {
            if (id !== null) {
                try {
                    setIsLoading(true);
                    const fetchedInfo = await viewModel.getByID(id);
                    setInfo(fetchedInfo);
                    console.log(fetchedInfo);
                    setIsLoading(false);  // Данные загружены
                } catch (error) {
                    console.error('Error fetching info:', error);
                    setIsLoading(false);  // Остановить индикатор загрузки при ошибке
                }
            }
        };
        fetchInfo();
    }, [id, viewModel]);

    useEffect(() => {
        if (info && info.image_id) {
            fetch(`${config.apiBaseUrl}/api/files/info/${info.image_id}`)
                .then((response) => response.json())
                .then((fileInfo) => {
                    const fileExtension = fileInfo.name.split('.').pop()?.toLowerCase();
                    if (fileExtension === 'mp4') {
                        setIsVideo(true);
                        setMediaSrc(`${config.apiBaseUrl}/api/files/stream/${info.image_id}`);
                    } else {
                        setIsVideo(false);
                        setMediaSrc(`${config.apiBaseUrl}/api/files/${info.image_id}`);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching file info:', error);
                });
        }
    }, [info]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleDownloadInfo = () => {
        if (info && info.file_id) {
            const fileUrl = `${config.apiBaseUrl}/api/files/${info.file_id}`;
            window.location.assign(fileUrl);
        }
    };

    return (
        <div className="activity-page">
            <CommonNavigation isBack />
            <div className="activity-page-info">
                {isLoading ? (  // Показать анимацию точек, если isLoading === true
                    <div className="loading-bar-container">
                        <p className="loading-text">{loadingText}</p>
                    </div>
                ) : (
                    id !== null && info ? (
                        <>
                            <CommonHeader name={info.type + ": " + info.name} />
                            <div className="activity-info-container">
                                <div className="activity-info-container-column">
                                    {isVideo ? (
                                        <video className="video-player" controls>
                                            <source src={mediaSrc} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : (
                                        <img className="activity-image" src={mediaSrc} alt={info.name} />
                                    )}
                                    <p>Тип: {info.type}</p>
                                    <div className="download-button">
                                        {info.file_id && (
                                            <CommonButton text="Скачать архив с данными" onClick={handleDownloadInfo} />
                                        )}
                                    </div>
                                </div>
                                <p className="activity-text">{info.text}</p>
                            </div>
                        </>
                    ) : (
                        <p>ID не указан или информация не найдена</p>
                    )
                )}
            </div>
        </div>
    );
};

export default ActivityInfo;
