import React, {useEffect, useState, useRef} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../css/registration_form/registration-form.css';
import AccountViewModel from '../../viewmodel/AccountViewModel';
import {AccountInfo} from "../../interface/accountInfo";
// @ts-ignore
import ic_person_registration from "../../img/ic_person_registration.png"; // Подключите ваш ViewModel

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const RegistrationFormPassword: React.FC = () => {
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null); // Добавляем состояние для ошибки
    const navigate = useNavigate();
    const query = useQuery();
    const id = query.get('id');
    const date_reg:string | null = query.get('date_reg');
    const viewModel = AccountViewModel.getInstance();
    const lastSubmitTime = useRef<number>(0); // Хранит время последней отправки формы

    useEffect(() => {
        const fetchData = async () => {
            try {
                let result = false;
                if (id != null) {
                    result = await viewModel.checkDateReg(parseInt(id));
                }
                if (result) {
                    navigate(`/reset?id=${id}`);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        return () => {};
    }, [id]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const currentTime = Date.now();
        if (currentTime - lastSubmitTime.current < 1000) {
            setError('Не вводите пароль слишком часто.');
            return;
        }

        lastSubmitTime.current = currentTime;

        const isPasswordCorrect = await AccountViewModel.getInstance().checkPassword(Number(id), password);

        if (isPasswordCorrect) {
            navigate(`/library`);
        } else {
            setError('Неверный пароль. Пожалуйста, попробуйте снова.'); // Устанавливаем сообщение об ошибке
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        setError(null); // Сбрасываем ошибку при изменении значения поля ввода
    };

    return (
        <div className="registration-container">
            <img className="emocion-logo-big" src={ic_person_registration} alt="emocion_logo_big"/>
            <label>Введите пароль</label>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        type="password"
                        placeholder="Введите пароль..."
                        value={password}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                {error && <div className="error-message"
                               style={{color: 'red'}}>{error}</div>} {/* Отображаем сообщение об ошибке */}
                <button type="submit">Отправить</button>
            </form>
        </div>
    );
};

export default RegistrationFormPassword;
