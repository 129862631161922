// src/components/LibraryListItem.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ListItemProps } from '../../interface/lazyList'; // Импорт интерфейса ListItemProps
import '../../css/library/library-list-item.css'; // Импорт стилей

// Импорт изображений
// @ts-ignore
import contestImage from '../../img/ic_contest.png';
// @ts-ignore
import scriptImage from '../../img/ic_scenario.png';
// @ts-ignore
import instructionImage from '../../img/ic_instruction.png';
// @ts-ignore
import danceImage from '../../img/ic-dance.svg';

const LibraryListItem: React.FC<ListItemProps> = ({ item }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/activity?id=${item.id}`);
    };

    // Логика для выбора изображения на основе типа
    const getImageByType = (type: string) => {
        switch (type) {
            case "Конкурс":
                return contestImage;
            case "Сценарий":
                return scriptImage;
            case "Инструкция":
                return instructionImage;
            case "Флешмоб":
                return danceImage;
            default:
                return contestImage; // Укажите изображение по умолчанию, если необходимо
        }
    };

    return (
        <div className="list-item" onClick={handleClick}>
            <div className="list-item-content">
                <p>{item.name}</p>
            </div>
            <img className="library_image" src={getImageByType(item.type)} alt={item.name} />
        </div>
    );
};

export default LibraryListItem;
