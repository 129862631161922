import React, { useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import '../../css/registration_form/registration-form.css';
import AccountViewModel from '../../viewmodel/AccountViewModel';

// @ts-ignore
import ic_person_registration from "../../img/ic_person_registration.png";
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const RegistrationFormResetPassword: React.FC = () => {
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const navigate = useNavigate();
    const viewModel = AccountViewModel.getInstance();
    const query = useQuery();

    const id = query.get('id');

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (newPassword !== confirmPassword) {
            console.error('Passwords do not match.');
            // Можно добавить логику для обработки несовпадения паролей (например, показать сообщение об ошибке)
            return;
        }

        if (id != null) {
            const isSuccess = await viewModel.checkResetPassword(parseInt(id), newPassword);
            if (isSuccess) {
                navigate(`/password?id=${id}`);
            } else {
                console.error('Failed to reset password.');
                // Можно добавить логику для обработки неудачного сброса пароля (например, показать сообщение об ошибке)
            }
        }


    };

    return (
        <div className="registration-container">
            <img className="emocion-logo-big" src={ic_person_registration} alt="emocion_logo_big"/>
            <label>Сбросить пароль</label>
            <form onSubmit={handleSubmit}>

                <div className="form-group">

                    <input
                        type="password"
                        placeholder="Новый пароль..."
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        placeholder="Подтвердите пароль..."
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Сбросить пароль</button>
            </form>
        </div>
    );
};

export default RegistrationFormResetPassword;
