import React, { useState, useEffect } from 'react';
import '../../../css/forms/forms-primitives.css';

interface ImageInputProps {
    label: string;
    name: string;
    onChange: (name: string, file: File | null) => void;
    value?: string;
    existingImageUrl?: string | null;
}

const ImageInput: React.FC<ImageInputProps> = ({ label, name, onChange, value, existingImageUrl }) => {
    const [imageUrl, setImageUrl] = useState<string | null>(value || existingImageUrl || null);

    useEffect(() => {
        if (value) {
            setImageUrl(value);
        } else if (existingImageUrl) {
            setImageUrl(existingImageUrl);
        }
    }, [value, existingImageUrl]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            const newImageUrl = URL.createObjectURL(file);
            setImageUrl(newImageUrl);
            onChange(name, file);
        } else {
            setImageUrl(null);
            onChange(name, null);
        }
    };

    return (
        <div className="primitive-container">
            <label>{label}</label>
            <label className="custom-button">
                <span ><p className="span-text">Выбрать файл</p> </span>
                <input
                    type="file"
                    accept="image/*"
                    className="file-input"
                    onChange={handleInputChange}
                />
            </label>
            {imageUrl && <img src={imageUrl} alt="Selected" className="preview-image" />}
        </div>
    );
};

export default ImageInput;
