import React from 'react';
import '../../../css/services/service_list_item.css'; // Импорт стилей
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import deleteImage from "../../../img/ic_delete.svg";

interface ListItemProps {
    imageUrl: string;
    text: string;
    onClick: () => void;
}

const ServiceIcon: React.FC<ListItemProps> = ({ imageUrl, text, onClick }) => {
    return (
        <div className="service-list-item-option" onClick={onClick}>
            <img src={imageUrl} alt="option-image" className="option-image" />
            <div className="service-list-item-content">
                <h2>{text}</h2>
            </div>
        </div>
    );
};

export default ServiceIcon;
