 import ModelAccount from "../model/ModelАccount";
import { Publisher } from "../interface/publisher";
 import {AccountInfo} from "../interface/accountInfo";
import {HotelInfo} from "../interface/hotelInfo";
// @ts-ignore
import Cookies from 'js-cookie';
 import {Item} from "../interface/library";
 import ModelLibrary from "../model/ModelLibrary";
 import modelLibrary from "../model/ModelLibrary";
 import config from "../config";

class LibraryViewModel implements Publisher {
    private static instance: LibraryViewModel;
    private info: Item[] | null | undefined;

    private libraryModel: ModelLibrary;

    private observers: ((info: Item) => void)[] = [];


    private constructor() {
        this.libraryModel = ModelLibrary.getInstance();
        // @ts-ignore
        this.libraryModel.subscribe(this.handleLibraryUpdate.bind(this));

    }

    public static getInstance(): LibraryViewModel {
        if (!LibraryViewModel.instance) {
            LibraryViewModel.instance = new LibraryViewModel();
        }
        return LibraryViewModel.instance;
    }
    public handleCheckAuth = () => {
        const cookieExists = Cookies.get('auth') !== undefined;

        if (cookieExists) {
            return true;
        } else {
            return false;
        }
    };

    public getInfo():any{
        return this.info;
    }
    public getByType = (category: string) => {
        const allInfo = this.libraryModel.getInfo();
        if (allInfo) {
            this.info = allInfo.filter((item: Item) => item.type === category);
        }
        else{
           this.fetchLibraryInfo();
        }
        return this.info;
    };
    public async getByID(id: number | null): Promise<Item | null> {
        if (id === null) {
            return null;
        }
        try {
            const item = await this.libraryModel.fetchByID(id);
            return item;
        } catch (error) {
            console.error('Error fetching item by ID:', error);
            return null;
        }
    }

    private handleLibraryUpdate(info:Item[] | null): void {
        this.info = info;
         this.notify();
    }


     public async fetchLibraryInfo(): Promise<void> {
         await this.libraryModel.fetchLibraryInfo();
    }
    public async searchLibraryInfo(name:string): Promise<void> {
        try {
            let url:string = config.apiBaseUrl + "/api/library/activity/search?name=";
            // Выполняем первый запрос
            const response1 = await fetch(url + name, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            });
            if (!response1.ok) {
                throw new Error('Network response was not ok');
            }
            this.info = await response1.json();

            this.notify();
        } catch (error) {
            console.error('Fetch account with token error:', error);
        }

    }
    public async fetchImageById(imageId: number): Promise<string> {
        try {
            const response = await fetch(config.apiBaseUrl + `/api/files/${imageId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const imageUrl = URL.createObjectURL(blob);
            return imageUrl;
        } catch (error) {
            console.error('Fetch image error:', error);
            throw error;
        }
    }
    // Publisher methods
    // @ts-ignore
    public subscribe(observer: OmitThisParameter<(info: (AccountInfo | null)) => void>): void {
        // @ts-ignore
        this.observers.push(observer);
    }

    public unsubscribe(observer: (info: any[] | null) => void): void {
        // @ts-ignore
        this.observers = this.observers.filter(subscriber => subscriber !== observer);
    }



    public notify(): void {
        const info = this.info;
        for (const observer of this.observers) {
            // @ts-ignore
            observer(info);
        }
    }

}

export default LibraryViewModel;
