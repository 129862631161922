// src/config.js
const config = {
    //apiBaseUrl: 'http://195.133.13.84:8070'
    //apiBaseUrl: "http://localhost:8050"
        apiBaseUrl: "https://mobile.ai-bd.ru"
    //apiBaseUrl: ""

};

export default config;
