import React, { useState, useEffect } from 'react';
import '../../../css/forms/forms-primitives.css';

interface FileInputProps {
    label: string;
    name: string;
    onChange: (name: string, file: File | null) => void; // Adjust onChange to handle null file
    value?: string | null  ; // Optional prop for initial file value
    existingFileUrl?: string | null; // Optional prop for existing file URL
    accept?: string; // New prop to specify accepted file types
}

const FileInput: React.FC<FileInputProps> = ({
                                                 label,
                                                 name,
                                                 onChange,
                                                 value,
                                                 existingFileUrl,
                                                 accept = '*/*' // Default to accept all files if not specified
                                             }) => {
    const [fileName, setFileName] = useState<string | null>(value || existingFileUrl || null);

    useEffect(() => {
        if (value) {
            setFileName(value);
        } else if (existingFileUrl) {
            setFileName(existingFileUrl);
        }
    }, [value, existingFileUrl]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            setFileName(file.name); // Update the file name
            onChange(name, file);
        } else {
            setFileName(null); // Clear file name if file is null
            onChange(name, null);
        }
    };

    return (
        <div className="primitive-container">
            <label>{label}</label>
            <label className="custom-button">
                <p>Выбрать файл</p>
                <input
                    type="file"
                    className="file-input"
                    accept={accept}
                    onChange={handleInputChange}
                />
            </label>
            {fileName && <p className="file-name">Выбранный файл: {fileName}</p>}
        </div>
    );
};

export default FileInput;
