 import ModelAccount from "../model/ModelАccount";
import { Publisher } from "../interface/publisher";
 import {AccountInfo} from "../interface/accountInfo";
import {HotelInfo} from "../interface/hotelInfo";
// @ts-ignore
import Cookies from 'js-cookie';
 import {Item} from "../interface/library";
 import config from "../config";
 import modelCcount from "../model/ModelАccount";

class AccountViewModel implements Publisher {
    private static instance: AccountViewModel;
    private info: AccountInfo | null | undefined;
    private person_info: AccountInfo | null | undefined;

    private accountModel: ModelAccount;

    private observers: ((info: any[] | null) => void)[] = [];
    private observers_person: ((info: any[] | null) => void)[] = [];


    private constructor() {
        this.accountModel = ModelAccount.getInstance();
        this.accountModel.subscribe(this.handleModelAccountUpdate.bind(this));

    }

    public static getInstance(): AccountViewModel {
        if (!AccountViewModel.instance) {
            AccountViewModel.instance = new AccountViewModel();
        }
        return AccountViewModel.instance;
    }
    public getInfo():any{
        return this.info;
    }



    private handleModelAccountUpdate(info:AccountInfo | null): void {
        this.info = info;
         this.notify();
    }


    // New helper function to ensure info is fetched if not available
    private async ensureAccountInfo(): Promise<AccountInfo | null> {
        if (!this.info) {
            await this.accountModel.fetchAccountInfoWithToken();
            this.info = this.accountModel.getInfo(); // Assume this fetches the data and stores it in the model
            console.log(this.info);
        }
        return this.info;
    }

    // Method to check if role_access is empty, with async info fetching
    public async isAccessEmpty(): Promise<boolean> {
        const accountInfo = await this.ensureAccountInfo();
        return accountInfo?.role_access.length === 0;
    }

    // Method to check for specific access
    public async hasAccess(accessName: string): Promise<boolean> {
        const accountInfo = await this.ensureAccountInfo();
        if (accountInfo && accountInfo.role_access) {
            return accountInfo.role_access.some((access) => access.name === accessName);
        }
        return false;
    }
     public async phoneLogin(phone: string): Promise<number | boolean> {
        const idOrFalse = await this.accountModel.phoneLogin(phone);
        if (idOrFalse !== false) {
            return idOrFalse;
        } else {
            console.error('Phone login failed.');
            return false;
        }
    }

    // Method to check password
    public async checkPassword(id: number, password: string): Promise<boolean> {
        const isCorrect = await this.accountModel.checkPassword(id, password);
        if (isCorrect) {
            return true;
        } else {
            console.error('Password incorrect.');
            return false;
        }
    }
    public async checkResetPassword(id: number, password: string): Promise<boolean> {
        const isCorrect = await this.accountModel.checkResetPassword(id, password);
        if (isCorrect) {
            return true;
        } else {
            console.error('Password incorrect.');
            return false;
        }
    }
    // @ts-ignore
    public async checkDateReg(id: number): Promise<boolean> {
        try {
            const url = config.apiBaseUrl + "/api/persons/"+id + "/profile"
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            let information = await response.json();
            return information.date_reg < 1;
        } catch (error) {
            console.error('Fetch error:', error);
        }

     }

    public handleCheckAuth = () => {
        // Лог всех куков

        const cookieExists = Cookies.get('auth') !== undefined;

        if (cookieExists) {
            return true;
        } else {
            return false;
        }
    };


     public async fetchAccountInfoWithToken(): Promise<void> {
         await this.accountModel.fetchAccountInfoWithToken();
    }

    // Publisher methods
    // @ts-ignore
    public subscribe(observer: OmitThisParameter<(info: (AccountInfo | null)) => void>): void {
        // @ts-ignore
        this.observers.push(observer);
    }

    public unsubscribe(observer: (info: any[] | null) => void): void {
        this.observers = this.observers.filter(subscriber => subscriber !== observer);
    }



    public notify(): void {
        const info = this.info;
        for (const observer of this.observers) {
            // @ts-ignore
            observer(info);
        }
    }

}

export default AccountViewModel;
