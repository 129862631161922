import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/registration_form/registration-form.css';
import ModelAccount from '../../model/ModelАccount';
import AccountViewModel from "../../viewmodel/AccountViewModel"; // Импортируем ModelAccount
// @ts-ignore
import ic_person_registration from "../../img/ic_person_registration.png"
const RegistrationFormLogin: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [error, setError] = useState<string | null>(null); // Добавляем состояние для ошибки
    const navigate = useNavigate();
    const model = ModelAccount.getInstance();
    const viewModel = AccountViewModel.getInstance();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const idOrFalse = await model.phoneLogin(username);

        if (idOrFalse !== false) {
            navigate(`/password?id=${idOrFalse}`);
        } else {
            console.error('Phone login failed.');
            setError('Ошибка входа. Пожалуйста, попробуйте снова.'); // Устанавливаем сообщение об ошибке
        }
    };

    useEffect(() => {
        if (viewModel.handleCheckAuth()) {
            navigate(`/library`);
        }
        return () => {};
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
        setError(null); // Сбрасываем ошибку при изменении значения поля ввода
    };

    return (
        <div className="registration-container">
            <img className="emocion-logo-big" src={ic_person_registration} alt="emocion_logo_big"/>
            <label>Введите логин</label>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Введите логин..."
                        value={username}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                {error && <div className="error-message">{error}</div>} {/* Отображаем сообщение об ошибке */}
                <button type="submit">Отправить</button>
            </form>
        </div>
    );
};

export default RegistrationFormLogin;
