import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TextInput from './primitive/TextInput';
import TextArea from './primitive/TextArea';
import Dropdown from './primitive/DropDownInput';
import ImageInput from './primitive/ImageInput';
import FileInput from './primitive/FileInput';
import "../../css/forms/forms-primitives.css";
import CommonHeader from "../common/CommonHeader";
import config from "../../config";
import CommonButton from "../common/CommonButton";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CommonNavigation from "../common/CommonNavigation";

interface EditActivityFormProps {}

const EditActivityForm: React.FC<EditActivityFormProps> = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const id = query.get("id");

    const [formData, setFormData] = useState<any>({});
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [isImageChanged, setIsImageChanged] = useState<boolean>(false);
    const [isFileChanged, setIsFileChanged] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [isVideoChanged, setIsVideoChanged] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [formErrors, setFormErrors] = useState<string[]>([]);

    useEffect(() => {
        if (id) {
            const fetchActivityData = async () => {
                try {
                    const response = await fetch(config.apiBaseUrl + `/api/library/activities/${id}`, {
                        credentials: 'include',
                    });
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setFormData(data);
                    if (data.image_id) {
                        if(data.type=="Флешмоб"){
                            fetchVideo(data.image_id);
                        }
                        else{
                            fetchImage(data.image_id);
                        }
                    }
                    if (data.file_id) {
                        fetchFile(data.file_id);
                    }
                } catch (error) {
                    console.error('Fetch error:', error);
                }
            };

            const fetchImage = async (imageId: string) => {
                fetch(`${config.apiBaseUrl}/api/files/${imageId}`)
                    .then((response) => response.blob())
                    .then((blob) => {
                        const fileUrl = URL.createObjectURL(blob);
                        setImageUrl(fileUrl);
                    })
                    .catch((error) => {
                        console.error('Error fetching file info:', error);
                    });
            };
            const fetchVideo = async (imageId: string) => {
                try {
                    const response = await fetch(config.apiBaseUrl + `/api/files/${imageId}`, {
                        credentials: 'include',
                    });
                    if (!response.ok) {
                        throw new Error('Failed to fetch video');
                    }
                    const imageData = await response.blob();

                    const videoUrl = URL.createObjectURL(imageData);
                    setVideoUrl(videoUrl);
                } catch (error) {
                    console.error('Video fetch error:', error);
                }
            };

            const fetchFile = async (fileId: string) => {
                try {
                    const response = await fetch(config.apiBaseUrl + `/api/files/${fileId}`, {
                        credentials: 'include',
                    });
                    if (!response.ok) {
                        throw new Error('Failed to fetch file');
                    }
                    const fileData = await response.blob();
                     const fileUrl = URL.createObjectURL(fileData);
                    setFileUrl(fileUrl);
                } catch (error) {
                    console.error('File fetch error:', error);
                }
            };

            fetchActivityData();
        }
    }, [id]);

    const handleChange = (name: string, value: any) => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value
        }));

        if (name === 'image') {
            setIsImageChanged(true);
        } else if (name === 'file') {
            setIsFileChanged(true);
        } else if(name === "video") {
            setIsVideoChanged(true)
        }

    };

    const validateForm = () => {
        const errors: string[] = [];
        if (!formData.type) errors.push("Выберите вид активности");
        if (!formData.name) errors.push("Введите название активности");
        if (!formData.text) errors.push("Введите описание активности");

        if (formData.type === "Флешмоб" && !formData.video && !videoUrl) {
            errors.push("Загрузите видео в формате MP4");
        } else if (formData.type !== "Флешмоб" && !formData.image && !imageUrl) {
            errors.push("Выберите картинку для активности");
        }

        setFormErrors(errors);
        return errors.length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            window.scrollTo(0, 0);
            return;
        }

        try {
            setUploading(true);
            setUploadProgress(0);

            const uploadFile = (file: File, url: string, onProgress: (progress: number) => void) => {
                return new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();
                    xhr.open('POST', url, true);
                    xhr.withCredentials = true;
                    xhr.upload.onprogress = (event) => {
                        if (event.lengthComputable) {
                            const progress = Math.round((event.loaded * 100) / event.total);
                            onProgress(progress);
                        }
                    };
                    xhr.onload = () => {
                        if (xhr.status >= 200 && xhr.status < 300) {
                            resolve(JSON.parse(xhr.responseText));
                        } else {

                            reject(`Error ${xhr.status}: ${xhr.statusText}`);
                        }
                    };
                    xhr.onerror = () => reject('Network error');
                    const formData = new FormData();
                    formData.append('file', file);
                    xhr.send(formData);
                });
            };

            let imageId = formData.image_id;
            if (isImageChanged && formData.image && formData.image instanceof File) {
                const uploadResponse = await uploadFile(formData.image, config.apiBaseUrl + '/api/files/', (progress) => {
                    setUploadProgress(progress);
                });
                // @ts-ignore
                imageId = uploadResponse.id;
            }

            let fileId = formData.file_id;
            if (isFileChanged && formData.file && formData.file instanceof File) {
                const uploadResponse = await uploadFile(formData.file, config.apiBaseUrl + '/api/files/', (progress) => {
                    setUploadProgress(progress);
                });
                // @ts-ignore
                fileId = uploadResponse.id;
            }

            if (formData.type === "Флешмоб" && isVideoChanged && formData.video && formData.video instanceof File) {
                const uploadResponse = await uploadFile(formData.video, config.apiBaseUrl + '/api/files/', (progress) => {
                    setUploadProgress(progress);
                });
                // @ts-ignore
                imageId = uploadResponse.id;
            }

            const updatedActivityData = {
                ...formData,
                image_id: imageId,
                file_id: fileId,
            };
            delete updatedActivityData.id;
            delete updatedActivityData.props;

            const url = config.apiBaseUrl + `/api/library/activities/${id}`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(updatedActivityData),
            });
            if (response.status === 403) {
                alert('У вас нет прав для осуществления этой функции');
                navigate(-1);
                return;
            }
            if (!response.ok) {
                throw new Error('Failed to update activity');
            }

            const result = await response.json();
            navigate(-1);
        } catch (error) {
            console.error('Fetch error:', error);
            navigate(-1);

        } finally {
            setUploading(false);
        }
    };

    return (
        <>
        <CommonNavigation isBack/>

    <div className="custom-form">
            <CommonHeader name="Редактировать активность"/>

            <div className="form-container">
                {formErrors.length > 0 && (
                    <div className="error-messages">
                        {formErrors.map((error, index) => (
                            <p key={index} className="error-message">{error}</p>
                        ))}
                    </div>
                )}
                <Dropdown
                    label="Выберите вид активности"
                    value={formData.type}
                    name="type"
                    options={["Конкурс", "Сценарий", "Инструкция", "Флешмоб"]}
                    onChange={handleChange}
                />
                <TextInput
                    label="Название активности"
                    placeholder="Введите короткий текст"
                    name="name"
                    value={formData.name || ''}
                    onChange={handleChange}
                />
                <TextArea
                    label="Описание активности"
                    placeholder="Введите описание..."
                    name="text"
                    value={formData.text || ''}
                    onChange={handleChange}
                />
                {formData.type === "Флешмоб" ? (
                    <FileInput
                        label="Загрузите видео в формате MP4"
                        name="video"
                        onChange={handleChange}
                        accept=".mp4"
                        value={videoUrl ? videoUrl : null}
                    />
                ) : (
                    <ImageInput
                        label={imageUrl ? "Выберите картинку для активности(уже существует)" : "Выберите картинку для активности"}
                        name="image"
                        onChange={handleChange}
                        existingImageUrl={imageUrl ? imageUrl : null}
                    />
                )}
                <FileInput
                    label="Выберите новый файл"
                    name="file"
                    onChange={handleChange}
                    value={fileUrl}
                    accept=".zip"
                />
                <div className="download-file-button">
                    {fileUrl && (
                        <CommonButton text="Скачать файл" onClick={() => {
                            const a = document.createElement('a');
                            a.href = fileUrl;
                            a.target = '_blank';
                            document.body.appendChild(a);
                            a.click();
                            a.remove();
                        }}/>
                    )}
                </div>
                {uploading && (
                    <div className="progress-bar">
                        <CircularProgressbar value={uploadProgress} text={`${uploadProgress}%`}/>
                    </div>
                )}
                <button onClick={handleSubmit} disabled={uploading}>
                    Сохранить
                </button>
            </div>
        </div>
        </>
    );
};

export default EditActivityForm;
