import { Publisher } from "../interface/publisher";
import { AccountInfo } from "../interface/accountInfo";
import { HotelInfo } from "../interface/hotelInfo";
import {Item} from "../interface/library";
import config from "../config";

class ModelAccount implements Publisher {
    private static instance: ModelAccount;
    private info: AccountInfo | null = null;
    private token: string | null = null; // Declare token property
    private baseUrl: string = config.apiBaseUrl + "/api/persons";
    private accountUrl: string = config.apiBaseUrl + "/api/account";
    private observers: ((info: AccountInfo | null) => void)[] = [];
    private hotelUrl: string = config.apiBaseUrl + "/api/hotels/";

    private constructor() {}

    public static getInstance(): ModelAccount {
        if (!ModelAccount.instance) {
            ModelAccount.instance = new ModelAccount();
        }
        return ModelAccount.instance;
    }

    public getInfo(): AccountInfo | null {
        return this.info;
    }

    public getToken(): string | null {
        return this.token; // Getter for token property
    }

    // Method to handle account info
    public async fetchAccountInfo(): Promise<void> {
        try {
            const response = await fetch(this.accountUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include' // To send cookies with the request
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const accountInfo = await response.json();
            this.info = accountInfo; // Save account info to this.info
            this.notify();
        } catch (error) {
            console.error('Fetch account error:', error);
        }
    }

    // Method to handle password check
    public async checkPassword(id: number, password: string): Promise<boolean> {
        try {
            const response = await fetch(`${this.baseUrl}/password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ id, password }),
                credentials: 'include',// To send cookies with the request

            });
            if (!response.ok) {
                return false;
            }
            return true;
        } catch (error) {
            console.error('Password check error:', error);
            return false;
        }
    }

    // Method to handle password reset
    public async checkResetPassword(id: number, password: string): Promise<boolean> {
        try {
            const response = await fetch(`${this.baseUrl}/reset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({ id, password }),
                // credentials: 'include' // To send cookies with the request
            });
            if (!response.ok) {
                return false;
            }
            return true;
        } catch (error) {
            console.error('Password reset error:', error);
            return false;
        }
    }

    // Method to handle phone login
    public async phoneLogin(phone: string): Promise<number | boolean> {
        try {
            const url = config.apiBaseUrl + `/api/persons/login?phone=${phone}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                },
                credentials: 'include'
            });


            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
            }

            const data = await response.json();
            return data || false;
        } catch (error) {
            console.error('Phone login error:', error);

            if (error instanceof TypeError && error.message === 'Failed to fetch') {
                console.error('This might be a CORS issue or a network problem.');
            } else {
                console.error('Other error:', error);
            }

            return false;
        }
    }

    // Method to fetch account info using token
    public async fetchAccountInfoWithToken(): Promise<void> {
        try {
            // Выполняем первый запрос
            const response1 = await fetch(this.accountUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            });
            if (!response1.ok) {
                throw new Error('Network response was not ok');
            }
            this.info = await response1.json();

            const new_acc_url = this.hotelUrl + this.info?.hotel_id;
            const response2 = await fetch(new_acc_url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include'
            });
            if (!response2.ok) {
                throw new Error('Network response was not ok');
            }
            const accountHotelInfo: HotelInfo = await response2.json();

            if (this.info && accountHotelInfo) {
                this.info.team = accountHotelInfo.team;
            }
            this.notify();
        } catch (error) {
            console.error('Fetch account with token error:', error);
        }
    }

    // Publisher methods
    // @ts-ignore
    public subscribe(observer: OmitThisParameter<(info: (AccountInfo | null)) => void>): void {
        this.observers.push(observer);
    }

    // @ts-ignore
    public unsubscribe(observer: (info: AccountInfo | null) => void): void {
        this.observers = this.observers.filter(subscriber => subscriber !== observer);
    }

    public notify(): void {
        for (const observer of this.observers) {
            if (this.info) {
                observer(this.info);
            } else {
                observer(null);
            }
        }
    }
}

export default ModelAccount;
