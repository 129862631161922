import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../css/services/service-main.css";
import CommonNavigation from "../../common/CommonNavigation";
import CommonHeader from "../../common/CommonHeader";
import LazyList from "../../common/LazyList";
import CommonSearchPanel from "../../common/CommonSearchPanel";
import ServiceElementListItem from "./ServiceElementListItem";
import ServiceElementAdapterHotel from "../../../viewmodel/adapters/ServiceElementAdapterHotel";
import { InfoEditor } from "../../../interface/infoEditor";
import { HotelInfo } from "../../../interface/hotelInfo";
import ServiceElementAdapterActivity from "../../../viewmodel/adapters/ServiceElementAdapterActivity";
// @ts-ignore
import Cookies from "js-cookie";
// @ts-ignore
import action_image from "../../../img/add_action_button.webp";
import ServiceElementAdapterPerson from "../../../viewmodel/adapters/ServiceElementAdapterPerson";

function ServiceElement() {
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const type = query.get("type");

    const [infoEditor, setInfoEditor] = useState<InfoEditor | null>(null);
    const [items, setItems] = useState<HotelInfo[] | null>(null);
    const [name, setName] = useState<string>("");
    const [filterChoices, setFilterChoices] = useState<string[]>([]);

    const handleCheckAuth = () => {
        const cookieExists = Cookies.get('auth') !== undefined;

        if (cookieExists) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        const fetchCookie = () => {
            if (!handleCheckAuth()) {
                navigate(`/`);
            }
        };

        fetchCookie();
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            let editor: InfoEditor | null = null;
            if (type) {
                if (type === "hotel") {
                    editor = new ServiceElementAdapterHotel();
                    setName("Отели");
                } else if (type === "activity") {
                    editor = new ServiceElementAdapterActivity();
                    setName("Активности");
                } else if (type === "person") {
                    editor = new ServiceElementAdapterPerson();
                    setName("Пользователи");
                }
                setInfoEditor(editor);

                if (editor) {
                    editor.initChoices();
                    setFilterChoices(editor.getFilterChoices());

                    await editor.getAllInfo(0, 300, (info) => {
                        setItems(info);
                    });
                }
            }
        };

        fetchData();
    }, [type]);

    useEffect(() => {
        if (infoEditor) {
            setFilterChoices(infoEditor.getFilterChoices());
        }
    }, [infoEditor]);

    const handleSubmit = async (text: string) => {
        if (infoEditor) {
            await infoEditor.getSearchInfo(text, (info) => {
                setItems(info);
            });
        }
    };

    const handleRefresh = async () => {
        if (infoEditor) {
            await infoEditor.getAllInfo(0, 200, (info) => {
                setItems(info);
            });
        }
    };

    const handleDelete = async (id: number) => {
        if (infoEditor) {
            await infoEditor.deleteVar(id, async () => {
                await handleRefresh();
            });
        }
    };

    const handleEditClick = (id: number) => {
        navigate(infoEditor?.getEditForm() + "?id=" + id); // Переходим на страницу редактирования с id элемента
    };

    const handleFilter = async (id: number) => {
        if (infoEditor) {
            await infoEditor.getFilterInfo(0, 200, id, (info) => {
                setItems(info);
            });
        }
    };

    const handleFabClick = () => {
        navigate(infoEditor?.getAddForm() || "");
    };

    const placeholder = "Еноты любят пить";

    return (
        <>
            <CommonNavigation />
            <div className="service-container">
                <CommonHeader name={name} />
                <CommonSearchPanel
                    onReload={handleRefresh}
                    onSearch={handleSubmit}
                    placeholder={placeholder}
                    showSearch
                    showFilter={filterChoices.length > 0}
                    onFilter={handleFilter}
                    filterCategories={filterChoices}
                />
                <div className="service-container-content-element">
                    <LazyList
                        items={items}
                        ListItemComponent={ServiceElementListItem}
                        onDelete={handleDelete}
                        onEdit={handleEditClick}
                        translations={infoEditor?.getTranslationsInfo()} // Передаем переводы
                    />
                </div>
                <img onClick={handleFabClick} className="fab" src={action_image} alt="action_button" />
            </div>
        </>
    );
}

export default ServiceElement;
