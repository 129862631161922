import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TextInput from './primitive/TextInput';
import TextArea from './primitive/TextArea';
import Dropdown from './primitive/DropDownInput';
import ImageInput from './primitive/ImageInput';
import FileInput from './primitive/FileInput';
import CommonHeader from "../common/CommonHeader";
import config from "../../config";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CommonNavigation from "../common/CommonNavigation";

const FormItemCreate: React.FC = () => {
    const [formData, setFormData] = useState<any>({});
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploading, setUploading] = useState<boolean>(false);
    const navigate = useNavigate();
    const formRef = useRef<HTMLDivElement>(null);

    const handleChange = (name: string, value: any) => {
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        const errors: string[] = [];
        if (!formData.type) errors.push("Выберите вид активности");
        if (!formData.name) errors.push("Введите название активности");
        if (!formData.text) errors.push("Введите описание активности");

        if (formData.type === "Флешмоб" && !formData.video) {
            errors.push("Загрузите видео в формате MP4");
        } else if (formData.type !== "Флешмоб" && !formData.image) {
            errors.push("Выберите картинку для активности");
        }

        if (errors.length > 0) {
            setFormErrors(errors);
            formRef.current?.scrollIntoView({ behavior: 'smooth' });
            return;
        }

        try {
            setUploading(true);
            let imageId = null;
            if (formData.image && formData.type !== "Флешмоб") {
                const imageData = new FormData();
                imageData.append('file', formData.image);
                const uploadResponse = await uploadFile('/api/files/', imageData);
                // @ts-ignore
                imageId = uploadResponse.id;
            }
            let fileId = null;
            if (formData.file) {
                const fileData = new FormData();
                fileData.append('file', formData.file);
                const uploadResponse = await uploadFile('/api/files/', fileData);
                // @ts-ignore
                fileId = uploadResponse.id;
            }
            if(formData.video && formData.type == "Флешмоб"){
                const fileData = new FormData();
                fileData.append('file', formData.video);
                const uploadResponse = await uploadFile('/api/files/', fileData);
                // @ts-ignore
                imageId = uploadResponse.id;
            }

            const activityData = {
                activity: {
                    ...formData,
                    image_id: imageId,
                    file_id: fileId
                },
                prop_ids: []
            };

            const createResponse = await fetch(config.apiBaseUrl + '/api/library/activities', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(activityData)
            });
            if (!createResponse.ok) {
                throw new Error('Failed to create activity');
            }

            const result = await createResponse.json();
            navigate(-1);
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setUploading(false);
            setUploadProgress(0);
        }
    };

    const uploadFile = async (url: string, fileData: FormData) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', config.apiBaseUrl + url, true);
            xhr.withCredentials = true;

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentComplete = (event.loaded / event.total) * 100;
                    setUploadProgress(percentComplete);
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const response = JSON.parse(xhr.responseText);
                    resolve(response);
                } else {
                    reject(new Error(`Failed to upload file: ${xhr.statusText}`));
                }
            };

            xhr.onerror = () => reject(new Error('Upload failed'));

            xhr.send(fileData);
        });
    };

    return (
        <>
        <CommonNavigation isBack/>
    <div className="custom-form">
            <CommonHeader name="Добавить активность"/>
            <div className="form-container" ref={formRef}>
                {formErrors.length > 0 && (
                    <div className="error-messages">
                        {formErrors.map((error, index) => (
                            <p key={index} className="error-message">{error}</p>
                        ))}
                    </div>
                )}
                <Dropdown
                    label="Выберите вид активности"
                    value={formData.type}
                    name="type"
                    options={["Конкурс", "Сценарий", "Инструкция", "Флешмоб"]}
                    onChange={handleChange}
                />
                <TextInput
                    label="Название активности"
                    placeholder="Введите короткий текст"
                    name="name"
                    onChange={handleChange}
                />
                <TextArea
                    label="Описание активности"
                    placeholder="Введите описание..."
                    name="text"
                    onChange={handleChange}
                />
                {formData.type === "Флешмоб" ? (
                    <FileInput
                        label="Загрузите видео в формате MP4"
                        name="video"
                        onChange={handleChange}
                        accept=".mp4"
                        value={formData.video ? URL.createObjectURL(formData.video) : null}
                    />
                ) : (

                    <ImageInput
                        label="Выберите картинку для активности"
                        name="image"
                        onChange={handleChange}
                        existingImageUrl={formData.image ? URL.createObjectURL(formData.image) : null}
                    />
                )}
                <FileInput
                    label="Выберите файл (для архивов)"
                    name="file"
                    onChange={handleChange}

                    accept=".zip"
                />
                {uploading && (
                    <div className="progress-bar">
                        <CircularProgressbar value={uploadProgress} text={`${Math.round(uploadProgress)}%`}/>
                    </div>
                )}
                {!uploading && (
                    <button onClick={handleSubmit} disabled={uploading}>
                        Отправить
                    </button>
                )}
            </div>
        </div>
        </>
    );
};


export default FormItemCreate;
