import React, { useEffect, useState } from "react";
import '../../css/common/common-lazy-list.css';

interface LazyListProps {
    items: any[] | null;
    ListItemComponent: React.FC<any>;
    onDelete?: (id: number) => void;
    onEdit?: (id: number) => void;
    translations?: { [key: string]: string };
}

const LazyList: React.FC<LazyListProps> = ({ items, ListItemComponent, onDelete, onEdit, translations }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [loadingText, setLoadingText] = useState('Прогрузка компонентов');
    const dots = ['.', '..', '...'];

    useEffect(() => {
        let dotIndex = 0;
        const interval = setInterval(() => {
            setLoadingText(`Прогрузка компонентов${dots[dotIndex]}`);
            dotIndex = (dotIndex + 1) % dots.length;
        }, 500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (items !== null) {
            setIsLoading(false); // Отключаем загрузку, если items не null
        }
    }, [items]);

    return (
        <div className="lazy-list">
            {isLoading ? (
                <p className="loading-message">{loadingText}</p>
            ) : (
                items && items.length > 0 ? (
                    items.map((item) => (
                        <div key={item.id}>
                            <ListItemComponent
                                item={item}
                                onDelete={onDelete ? () => onDelete(item.id) : undefined}
                                onEdit={onEdit ? () => onEdit(item.id) : undefined}
                                {...(translations ? { translations } : {})}
                            />
                        </div>
                    ))
                ) : (
                    <p className="empty-message">Здесь пока ничего нет</p>
                )
            )}
        </div>
    );
};

export default LazyList;
