import React from "react";
import "../../css/common/common-search-input.css";

interface SearchInputProps {
    placeholder: string;
    onSubmit: (searchText: string) => void;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CommonSearchInput: React.FC<SearchInputProps> = ({ placeholder, onSubmit, value, onChange }) => {
    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(value.trim());
    };

    return (
        <div className="search-container">
            <form onSubmit={handleFormSubmit}>
                <input
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    className="search-input"
                />
            </form>
        </div>
    );
};

export default CommonSearchInput;
