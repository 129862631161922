import { InfoEditor } from '../../interface/infoEditor';
import { HotelInfo, TeamMember } from '../../interface/hotelInfo';
import ActivityInfo from "../../view/library/ActivityInfo";
import {Item, Prop} from "../../interface/library";
import {start} from "node:repl";
import {Simulate} from "react-dom/test-utils";
import config from "../../config";

class HotelInfoEditor implements InfoEditor {
    private hotelInfo: Item[];
    private filterChoices: string[] = [];

    constructor() {
        this.hotelInfo = [new class implements Item {
            name: string = "1";
            text: string = "1";
            type: string = "Конкурс";
            rating: number = 2;
            rating_count: number = 3;
            image_id: number = 1;
            props: Prop[] = [];
            id: number = 1;
        }];
    }

    async getAllInfo(start: number, end: number, observer: (info: any) => void): Promise<void> {
        try {
            const url =config.apiBaseUrl +  `/api/library/activities?start=${start}&end=${end}`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const information = await response.json();
            observer(information); // This was using this.hotelInfo, changed to information
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

     filterNameAdapter(id:number):string{
        switch (id){
            case 0:
                return "Конкурс";
            case 1:
                return "Сценарий";
            case 2:
                return "Инструкция";
            default:
                return "Конкурс";
        }
    }
    async getFilterInfo(start: number, end: number, id: number, observer: (info: any) => void): Promise<void> {
        let name = this.filterNameAdapter(id);
        try {
            const url = config.apiBaseUrl + "/api/library/activity/filter?name=" + name;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const information = await response.json();
            observer(information);
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    async getSearchInfo(name: string, observer: (info: any) => void): Promise<void> {
        try {
            const url = config.apiBaseUrl + "/api/library/activity/search?name="  + name;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const information = await response.json();
            observer(information);
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    getContextInfo(): Array<{ [key: string]: any }> {
        return this.hotelInfo.flatMap(item => item.props).map(prop => {
            const obj: { [key: string]: any } = {};
            Object.entries(prop).forEach(([key, value]) => {
                obj[key] = value;
            });
            return obj;
        });
    }
    getFilterChoices(): string[] {
        return this.filterChoices;
    }

    getEditForm(): string {
        return "/forms/edit-activity"
    }

    async deleteVar(id: number, observer: (info: any) => void): Promise<void> {
        try {
            const url = config.apiBaseUrl + `/api/library/activities/${id}`;
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'

            });
            if (response.status === 403) {
                alert('У вас нет прав для осуществления этой функции');
                window.location.reload();
                return;
            }
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            observer(await response.json());
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    initChoices(): void {
        this.filterChoices = ["Конкурсы", "Сценарии","Инструкции"]
    }

    setChoices(choices: string[]): void {
        this.filterChoices = choices;
    }

    getAddForm(): string {
        return "/forms/create-activity"

    }

    getTranslationsInfo(): { [key: string]: string } {
        // Пример реализации метода
        const translations: { [key: string]: string } = {
            type: "Тип",
        };
        return translations;
    }
}

export default HotelInfoEditor;
