import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AccountViewModel from "../../viewmodel/AccountViewModel"; // Import your ViewModel
// @ts-ignore
import Cookies from 'js-cookie'; // Import cookies for handling logout

// @ts-ignore
import library_image from "../../img/ic_nav_library.svg";
// @ts-ignore
import service_image from "../../img/ic_nav_service.svg";
// @ts-ignore
import back_image from "../../img/ic_nav_back.svg"; // Изображение стрелки назад

import "../../css/common/common-navigation.css";
import CommonButton from "./CommonButton";

interface CommonNavigationProps {
    isBack?: boolean;
}

function CommonNavigation({ isBack = false }: CommonNavigationProps) {
    const navigate = useNavigate();
    const [isFixedVisible, setIsFixedVisible] = useState<boolean>(false);
    const [isHidden, setIsHidden] = useState<boolean>(false);
    const [hasServiceAccess, setHasServiceAccess] = useState<boolean>(false); // State for access control
    const navigationRef = useRef<HTMLDivElement>(null);

    const handleImageClick = (route: string) => {
        navigate(route);
    };
    const handleLogout = () => {
        Cookies.remove('auth'); // Remove the auth cookie
        navigate('/'); // Redirect to login page
    };
    const handleBackClick = () => {
        navigate(-1); // Go back
    };

    useEffect(() => {
        const accountViewModel = AccountViewModel.getInstance();

        const checkAccess = async () => {
            const isAccessEmpty = await accountViewModel.isAccessEmpty();
            console.log(isAccessEmpty);
            setHasServiceAccess(!isAccessEmpty); // If access is not empty, set to true
        };

        checkAccess(); // Call the async function
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            const offsetTop = navigationRef.current?.offsetTop || 0;
            const scrollY = window.scrollY;

            if (scrollY > offsetTop + 200) {
                if (!isFixedVisible) {
                    setIsHidden(false);
                    setIsFixedVisible(true);
                }
            } else {
                if (isFixedVisible) {
                    setIsHidden(true);
                    setTimeout(() => {
                        setIsFixedVisible(false);
                    }, 500); // Match with CSS transition
                }
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isFixedVisible]);

    const renderNavigationItems = () => (
        <>
            <div className="navigation-row">
                {isBack && (
                    <img
                        className="nav_image"
                        src={back_image}
                        alt="back"
                        onClick={handleBackClick}
                    />
                )}
                <img
                    className="nav_image"
                    src={library_image}
                    alt="library"
                    onClick={() => handleImageClick("/library")}
                />
                {hasServiceAccess && (
                    <img
                        className="nav_image"
                        src={service_image}
                        alt="service"
                        onClick={() => handleImageClick("/services")}
                    />
                )}
            </div>
            <div className="navigation-exit">
                <CommonButton text="Выйти" onClick={handleLogout}/>
            </div>

        </>
    );

    return (
        <>
            <div ref={navigationRef} className="navigation">
                {renderNavigationItems()}
            </div>

            {isFixedVisible && (
                <div className={`navigation fixed ${isHidden ? "hidden" : ""}`}>
                    {renderNavigationItems()}
                </div>
            )}
        </>
    );
}

export default CommonNavigation;
